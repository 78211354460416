export const tableMixin = {
  data() {
    return {
      column: [
        {
          key: 'name',
          label: 'Khách hàng',
          sortable: true,
        },
        {
          key: 'actionDropdown',
          label: '',
          sortable: false,
        },
        {
          key: 'startDate',
          label: 'Ngày bắt đầu',
          sortable: false,
        },
        {
          key: 'endDate',
          label: 'Ngày kết thúc',
          sortable: false,
        },
        {
          key: 'classification',
          label: 'Đánh giá cuối khoá',
          sortable: false,
        },
        {
          key: 'classificationForCustomer',
          label: 'Đánh giá cuối khoá cho học viên',
          sortable: false,
        },
      ],
    };
  },

  methods: {
    onClickAction(item = {}, type) {
      switch (type) {
        case 'UserDashboard':
          return this.goToUserDashboard(item);
        case 'UserApp':
          return this.goToUserApp(item);

        default:
          return this.goToUserDashboard(item);
      }
    },

    goToUserDashboard(item) {
      const routeData = this.$router.resolve({
        name: 'user_dashboard',
        params: { id: item.patientId },
      });
      window.open(routeData.href, '_blank');
    },

    goToUserApp(item) {
      const routeData = this.$router.resolve({
        name: 'user_app_view',
        params: { id: item.patientId },
      });
      window.open(routeData.href, '_blank');
    },

    formatDate(d) {
      const date = this.$moment.unix(d)
      if (!date.isValid()) return '';

      return date.format('DD/MM/YYYY');
    },
  },
};
